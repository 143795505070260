import Base from "../utils/base";
// import EventName from "../utils/eventName";
import Swiper from "swiper";

export default class SnippetSlide {
  private _snippetSlide: NodeListOf<HTMLElement> | null;
  constructor() {
    // class変数
    console.log("[init] SnippetSlide");
    this._snippetSlide = Base._d.querySelectorAll(".js-snippetCarousels");
    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
    Array.prototype.forEach.call(this._snippetSlide, (slider) => {
      // btn.addEventListener(EventName.CLICK, this.toggleClass.bind(this));
      new Swiper(slider as HTMLElement, {
        spaceBetween: 86,
        effect: "slide",
        loop: false,
        autoplay: false,
        speed: 500,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        slidesPerView: 1,
        // freeMode: true,
        // freeModeSticky: true,
        // breakpoints: {
        //   1025: {
        //     slidesPerGroup: 4,
        //   },
        // },
      });
    });
  }
}
