import Base from "../utils/base";
// import EventName from "../utils/EventName";
import FaqAside from "../module/faq-aside";

export default class FaqDetail {
  private _faqContent: NodeListOf<HTMLElement>;

  constructor() {
    // class変数
    console.log("[init] FaqDetail");
    this._faqContent = Base._d.querySelectorAll(".faq__content");

    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
    new FaqAside();
    setTimeout(() => {
      Base.addClass(this._faqContent[0], "is-fi");
    }, 500);
  }
}
