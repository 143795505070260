import Base from "../utils/base";
// import EventName from "../utils/eventName";

export default class HeadingAnimation {
  private readonly _headings: NodeListOf<HTMLElement> | null;
  constructor() {
    // class変数
    console.log("[init] HeadingAnimation");
    this._headings = Base._d.querySelectorAll(".js-heading");
    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
    Array.prototype.forEach.call(this._headings, (head: HTMLElement) => {
      // head.addEventListener(EventName.CLICK, this.toggleClass.bind(this));
      const arraySplitSyntax = [...head.innerText];
      // console.log(arraySplitSyntax);
      // 対象のテキストを<span>で分割
      let _splitTxt = "";
      Array.prototype.forEach.call(arraySplitSyntax, (text: string, i: number) => {
        _splitTxt = _splitTxt + '<span style="transition-delay:' + i * 50 + 'ms ">' + text + "</span>";
      });
      head.innerHTML = _splitTxt;
    });
    this.addEvent();
  }

  public addEvent(): void {
    Base._w.onscroll = () => {
      this.posCheck();
    };
  }
  public posCheck(): void {
    const posY: number = Base._w.pageYOffset;
    const wh: number = Base._w.innerHeight;
    // 対象要素がウインドウの2/3より上にきたら表示する
    const Threshold: number = posY + (wh / 3) * 2;
    Array.prototype.forEach.call(this._headings, (head: HTMLElement) => {
      if (!Base.hasClass(head.parentNode as HTMLElement, "is-animate")) {
        const tgtPosY: number = posY + head.getBoundingClientRect().top;
        if (tgtPosY < Threshold) {
          Base.addClass(head.parentNode as HTMLElement, "is-animate");
        }
      }
    });
  }
  // public toggleClass(e: Event): void {
  //   const tgt: HTMLElement = e.target as HTMLElement;
  //   if (Base.hasClass(tgt, "is-open")) {
  //     Base.removeClass(tgt, "is-open");
  //   } else {
  //     Base.addClass(tgt, "is-open");
  //   }
  // }
}
