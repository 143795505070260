import Swiper from "swiper";
import Base from "../utils/base";
// import EventName from "../utils/EventName";

export default class Ndi {
  private _toolsSlide: HTMLElement | null;
  private _swiper: Swiper;
  constructor() {
    // class変数
    console.log("[init] Ndi");
    this._toolsSlide = Base._d.getElementById("js-toolsSlide");
    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
    this._swiper = new Swiper(this._toolsSlide as HTMLElement, {
      spaceBetween: 30,
      effect: "slide",
      loop: true,
      autoplay: false,
      speed: 800,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      slidesPerView: 1,
      slidesPerGroup: 1,
      breakpoints: {
        750: {
          slidesPerView: 5,
          slidesPerGroup: 5,
        },
      },
    });
  }
}
