// import Base from "../utils/base";
// import EventName from "../utils/EventName";

export default class Archive {
  // private _seriesBtn: NodeListOf<HTMLElement> | null;

  constructor() {
    // class変数
    console.log("[init] Archive");
    // this._seriesBtn = Base._d.querySelectorAll(".js-seriesBtn");

    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
  }
}
