import Base from "../utils/base";
// import EventName from "../utils/EventName";
import FaqAside from "../module/faq-aside";

export default class NdiProduct {
  private _ndiProductList: NodeListOf<HTMLElement>;
  constructor() {
    // class変数
    console.log("[init] NdiProduct");
    this._ndiProductList = Base._d.querySelectorAll(".ndi__product__list");

    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
    new FaqAside();
    const url = new URL(location.href);
    const params = new URLSearchParams(url.search);
    if (params.toString().length > 0 && Base._w.innerWidth < 1301) {
      Base.scrollWindow("#product-list", { duration: 500, offset: -110 });
    }
    setTimeout(() => {
      Base.addClass(this._ndiProductList[0], "is-fi");
    }, 800);
  }
}
