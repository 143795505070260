import Base from "../utils/base";
import EventName from "../utils/eventName";

export default class Menu {
  private _header: HTMLElement | null;
  private _menuBtn: HTMLElement | null;
  private _navBg: HTMLElement | null;
  private _closeTrigger: NodeListOf<HTMLElement>;
  private _hoverTrigger: NodeListOf<HTMLElement>;
  private _hoverTarget: NodeListOf<HTMLElement>;
  private _parentBtn: NodeListOf<HTMLElement>;
  private _parentTarget: NodeListOf<HTMLElement>;
  private _childBtn: NodeListOf<HTMLElement>;
  private _childTarget: NodeListOf<HTMLElement>;
  private BREAK_POINT_SP: number;
  private scrollable: boolean;
  constructor() {
    // class変数
    console.log("[init] Menu");
    this._header = Base._d.getElementById("js-header");
    this._menuBtn = Base._d.getElementById("js-menuBtn");
    this._navBg = Base._d.getElementById("js-navBg");
    this._closeTrigger = Base._d.querySelectorAll(".js-closeTrigger");
    this._hoverTrigger = Base._d.querySelectorAll(".js-hoverTrigger");
    this._hoverTarget = Base._d.querySelectorAll(".js-hoverTarget");
    this._parentBtn = Base._d.querySelectorAll(".js-parentBtn");
    this._parentTarget = Base._d.querySelectorAll(".js-parentTarget");
    this._childBtn = Base._d.querySelectorAll(".js-childBtn");
    this._childTarget = Base._d.querySelectorAll(".js-childTarget");
    this.BREAK_POINT_SP = 1380;
    this.scrollable = true;
    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
    this.addEvent();
  }
  private addEvent(): void {
    // 以下共通で追加するEVENT
    if (this._menuBtn) {
      this._menuBtn.addEventListener(EventName.CLICK, this.toggleMenu.bind(this));
    }
    if (this._navBg) {
      this._navBg.addEventListener(EventName.MOUSE_ENTER, this.pcMenuClose.bind(this));
    }
    Array.prototype.forEach.call(this._closeTrigger, (cTrigger) => {
      cTrigger.addEventListener(EventName.MOUSE_ENTER, this.pcMenuClose.bind(this));
    });
    Array.prototype.forEach.call(this._hoverTrigger, (hTrigger) => {
      hTrigger.addEventListener(EventName.MOUSE_ENTER, this.pcMenuOpen.bind(this));
    });
    Array.prototype.forEach.call(this._parentBtn, (parent) => {
      parent.addEventListener(EventName.CLICK, this.toggleParent.bind(this));
    });
    Array.prototype.forEach.call(this._childBtn, (child) => {
      child.addEventListener(EventName.CLICK, this.toggleChild.bind(this));
    });
    // document.addEventListener(EventName.TOUCH_MOVE, this.windowScroll.bind(this), { passive: false });
  }
  private pcMenuOpen(e: MouseEvent): void {
    console.log(">>>>>>>>> pcMenuOpen");
    if (Base._w.innerWidth > this.BREAK_POINT_SP) {
      const eTgt: HTMLElement = e.target as HTMLElement;
      Array.prototype.forEach.call(this._hoverTarget, (target) => {
        Base.removeClass(target, "is-open");
      });
      Base.addClass(this._header as HTMLElement, "is-open");
      const hTgt: HTMLElement = (eTgt.parentNode as HTMLElement).querySelector(".js-hoverTarget") as HTMLElement;
      Base.addClass(hTgt as HTMLElement, "is-open");
      Base.addClass(Base._b, "is-lock");
      this.scrollable = false;
    }
  }
  private pcMenuClose(): void {
    console.log(">>>>>>>>> pcMenuClose");
    if (Base._w.innerWidth > this.BREAK_POINT_SP) {
      Array.prototype.forEach.call(this._hoverTarget, (target) => {
        Base.removeClass(target, "is-open");
      });
      Base.removeClass(this._header as HTMLElement, "is-open");
      Base.removeClass(Base._b, "is-lock");
      this.scrollable = true;
    }
  }
  private toggleMenu(): void {
    console.log(">>>>>>>>> toggleMenu");
    if (!Base.hasClass(this._header as HTMLElement, "is-open")) {
      Base.addClass(this._header as HTMLElement, "is-open");
      Base.addClass(Base._b, "is-lock");
      this.scrollable = false;
    } else {
      Base.removeClass(this._header as HTMLElement, "is-open");
      Array.prototype.forEach.call(this._parentBtn, (tgt) => {
        Base.removeClass(tgt, "is-open");
      });
      Array.prototype.forEach.call(this._childBtn, (tgt) => {
        Base.removeClass(tgt, "is-open");
      });
      Array.prototype.forEach.call(this._parentTarget, (tgt) => {
        Base.removeClass(tgt, "is-open");
      });
      Array.prototype.forEach.call(this._childTarget, (tgt) => {
        Base.removeClass(tgt, "is-open");
      });
      Base.removeClass(Base._b, "is-lock");
      this.scrollable = true;
    }
  }
  private toggleParent(e: Event): void {
    console.log(">>>>>>>>> toggleParent");
    const eTgt: HTMLElement = e.target as HTMLElement;
    const pTgts: NodeListOf<HTMLElement> = (eTgt.parentNode as HTMLElement).querySelectorAll(".js-parentTarget");
    if (!Base.hasClass(eTgt, "is-open")) {
      Base.addClass(eTgt as HTMLElement, "is-open");
      Array.prototype.forEach.call(pTgts, (tgt) => {
        Base.addClass(tgt, "is-open");
      });
    } else {
      Base.removeClass(eTgt as HTMLElement, "is-open");
      Array.prototype.forEach.call(pTgts, (tgt) => {
        Base.removeClass(tgt, "is-open");
      });
    }
  }
  private toggleChild(e: Event): void {
    console.log(">>>>>>>>> toggleChild");
    const eTgt: HTMLElement = e.target as HTMLElement;
    const cTgts: NodeListOf<HTMLElement> = (eTgt.parentNode as HTMLElement).querySelectorAll(".js-childTarget");
    if (!Base.hasClass(eTgt, "is-open")) {
      Base.addClass(eTgt as HTMLElement, "is-open");
      Array.prototype.forEach.call(cTgts, (tgt) => {
        Base.addClass(tgt, "is-open");
      });
    } else {
      Base.removeClass(eTgt as HTMLElement, "is-open");
      Array.prototype.forEach.call(cTgts, (tgt) => {
        Base.removeClass(tgt, "is-open");
      });
    }
  }
  // private windowScroll(e: Event): void {
  //   if (!this.scrollable) {
  //     // console.log(e.target);
  //     // e.preventDefault();
  //   }
  // }
}
