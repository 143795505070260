// import "lazysizes";
// import "lazysizes/plugins/parent-fit/ls.parent-fit";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import TweenTarget = gsap.TweenTarget;

gsap.registerPlugin(ScrollTrigger);

import Home from "./views/home";
import Page from "./views/page";
// import Detail from "./pages/detail";
import Archive from "./views/archive";
import Single from "./views/single";
import Product from "./views/product";
import ProductDetail from "./views/product-detail";
import Faq from "./views/faq";
import FaqDetail from "./views/faq-detail";
import Search from "./views/search";
import Contact from "./views/contact";
import Ndi from "./views/ndi";
import NdiProduct from "./views/ndi-product";
import NdiPage from "./views/ndi-page";
import Base from "./utils/Base";
import EventName from "./utils/eventName";
import Menu from "./module/menu";
import HeadingAnimation from "./module/heading-animation";

class AppClass {
  private _body: HTMLBodyElement;
  private _a: HTMLCollectionOf<HTMLAnchorElement>;
  private _header: HTMLElement | null;
  private _topBtn: HTMLElement | null;
  constructor() {
    // class変数
    new Base();
    new EventName();
    console.log("[init] App");
    this._body = Base._b;
    this._a = Base._d.getElementsByTagName("a");
    this._header = Base._d.getElementById("js-header");
    this._topBtn = Base._d.getElementById("js-topBtn");
    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
    const page: string = this._body.getAttribute("data-page") as string;
    switch (page) {
      case "home":
        new Home();
        break;
      case "page":
        new Page();
        break;
      case "archive":
        new Archive();
        break;
      case "single":
        new Single();
        break;
      case "product":
        new Product();
        break;
      case "product-detail":
        new ProductDetail();
        break;
      case "faq":
        new Faq();
        break;
      case "faq-detail":
        new FaqDetail();
        break;
      case "search":
        new Search();
        break;
      case "contact":
        new Contact();
        break;
      case "ndi":
        new Ndi();
        break;
      case "ndi-product":
        new NdiProduct();
        break;
      case "ndi-page":
        new NdiPage();
        break;
    }
    new Menu();
    this.addEvent();
    this.scrollbarCheck();
    new HeadingAnimation();
  }
  private addEvent(): void {
    // 以下共通で追加するEVENT
    Array.prototype.forEach.call(this._a, (el) => {
      const target = el.getAttribute("href");
      if (target.match(/^#/)) {
        if (Base.ua.indexOf("msie 9") > 0) {
          return;
        }
        el.addEventListener(EventName.CLICK, Base.smoothScroll);
      }
    });
    if (this._topBtn) {
      this._topBtn.addEventListener(EventName.CLICK, () => {
        Base.scrollWindow("#", { duration: 300 });
      });
    }

    const items = gsap.utils.toArray("[data-animate]");
    items.forEach((item) => {
      gsap.fromTo(
        item as TweenTarget,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 1.2,
          ease: "power2.out",
          scrollTrigger: {
            // markers: true,
            trigger: item as HTMLElement,
            start: "top center+=20%",
            // end: "top center+=90%",
          },
        }
      );
    });
  }

  private removeEvent(): void {
    // 以下共通で削除するEVENT
    Array.prototype.forEach.call(this._a, (el) => {
      const target = el.getAttribute("href");
      if (target.match(/^#/)) {
        if (Base.ua.indexOf("msie 9") > 0) {
          return;
        }
        el.removeEventListener(EventName.CLICK, Base.smoothScroll);
      }
    });
  }

  private scrollbarCheck(): void {
    if (Base._w.innerWidth - Base._d.body.clientWidth > 0) {
      Base.addClass(Base._b, "is-scrollbar");
    }
  }
  // public showModal(): void {
  //   const modal: HTMLElement = Base._d.getElementById("data-modal") as HTMLElement;
  //   Base.addClass(modal, "is-open");
  //   anime({
  //     targets: modal,
  //     opacity: 1,
  //     duration: 500,
  //     easing: "easeOutQuad",
  //     complete: () => {
  //       Base.addClass(modal, "is-anim");
  //       modal.addEventListener(EventName.CLICK, this.hideModal);
  //       const modalClose: HTMLElement = Base._d.getElementById("data-modal-close") as HTMLElement;
  //       modalClose.addEventListener(EventName.CLICK, this.hideModal);
  //     },
  //   });
  // }
  // public hideModal(e: Event): void {
  //   const $target: HTMLElement = e.target as HTMLElement;
  //   if ($target.id.toLowerCase() !== "data-modal" && $target.id.toLowerCase() !== "data-modalclose") {
  //     // 処理
  //     return;
  //   }
  //
  //   const modal: HTMLElement = Base._d.getElementById("data-modal") as HTMLElement;
  //   anime({
  //     targets: modal,
  //     opacity: 0,
  //     duration: 200,
  //     easing: "easeInQuad",
  //     complete: () => {
  //       Base.removeClass(modal, "is-anim");
  //       Base.removeClass(modal, "is-open");
  //       modal.removeEventListener(EventName.CLICK, this.hideModal);
  //       const modalClose: HTMLElement = Base._d.getElementById("data-modal-close") as HTMLElement;
  //       modalClose.removeEventListener(EventName.CLICK, this.hideModal);
  //     },
  //   });
  // }
}
window.onload = () => {
  new AppClass();
};
