// import Base from "../utils/base";
// import EventName from "../utils/EventName";
import FaqAside from "../module/faq-aside";

export default class Faq {
  constructor() {
    // class変数
    console.log("[init] Faq");

    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
    new FaqAside();
  }
}
