import Swiper from "swiper";
import Base from "../utils/base";
// import EventName from "../utils/EventName";

export default class Home {
  private _topSlider: HTMLElement | null;
  private _showroomSlider: HTMLElement | null;
  private _nonLoopSlide: NodeListOf<HTMLElement>;
  private _swiper: Swiper;
  private _swiperTimer: number | undefined;

  private _sliderTransition: HTMLElement | null;
  private _sliderTransitionItems: NodeListOf<HTMLElement>;
  private _sliderItemTxt: NodeListOf<HTMLElement> | null;
  private loopCnt: number;
  private lightingItems: number;

  constructor() {
    // class変数
    console.log("[init] Home");
    this._topSlider = Base._d.getElementById("js-topSlider");
    this._showroomSlider = Base._d.getElementById("js-showroomSlider");
    this._nonLoopSlide = Base._d.querySelectorAll(".js-nonLoopSlide");
    this._swiperTimer = undefined;

    this._sliderTransition = Base._d.getElementById("js-sliderTransition");
    this._sliderTransitionItems = Base._d.querySelectorAll("#js-sliderTransition .slider__transition__item > div");
    // this._sliderItemTxt = Base._d.querySelectorAll("#js-topSlider .slider__item__txt");
    this.loopCnt = 0;
    this.lightingItems = 24;
    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
    this._swiper = new Swiper(this._topSlider as HTMLElement, {
      spaceBetween: 0,
      effect: "fade",
      loop: true,
      // autoplay: {
      //   delay: 8000,
      //   disableOnInteraction: false,
      // },
      autoplay: false,
      speed: 800,
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      slidesPerView: 1,
      initialSlide: -1,
      // freeMode: true,
      // freeModeSticky: true,
      // breakpoints: {
      //   1025: {
      //     slidesPerGroup: 4,
      //   },
      // },
      on: {
        init: () => {
          this._sliderItemTxt = Base._d.querySelectorAll("#js-topSlider .slider__item__txt");
          Array.prototype.forEach.call(this._sliderItemTxt, (txt) => {
            Base.addClass(txt, "is-off");
          });
          setTimeout(() => {
            this.transitionLoop();
          }, 300);
        },
        slideChange: () => {
          clearTimeout(this._swiperTimer);
          // console.log("swiper slideChange");
          this._swiperTimer = Base._w.setTimeout(() => {
            this._sliderItemTxt = Base._d.querySelectorAll("#js-topSlider .slider__item__txt");
            Array.prototype.forEach.call(this._sliderItemTxt, (txt) => {
              Base.addClass(txt, "is-off");
            });

            setTimeout(() => {
              this.transitionLoop();
            }, 450);
          }, 8000);
        },
      },
    });
    Array.prototype.forEach.call(this._nonLoopSlide, (slide) => {
      const reverse: boolean = slide.getAttribute("dir") === "rtl" ? true : false;
      new Swiper(slide as HTMLElement, {
        spaceBetween: 86,
        effect: "slide",
        loop: true,
        autoplay: false,
        speed: 500,
        navigation: {
          nextEl: reverse ? ".swiper-button-prev" : ".swiper-button-next",
          prevEl: reverse ? ".swiper-button-next" : ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        slidesPerView: 1,
        // freeMode: true,
        // freeModeSticky: true,
        // breakpoints: {
        //   1025: {
        //     slidesPerGroup: 4,
        //   },
        // },
      });
    });
    if (this._showroomSlider) {
      new Swiper(this._showroomSlider as HTMLElement, {
        spaceBetween: 5,
        loop: true, // ループ有効
        slidesPerView: 2, // 一度に表示する枚数
        speed: 6000, // ループの時間
        allowTouchMove: false, // スワイプ無効
        autoplay: {
          delay: 0, // 途切れなくループ
          // reverseDirection: true, // 逆方向有効化
        },
        breakpoints: {
          750: {
            spaceBetween: 10,
            slidesPerView: 5,
          },
        },
      });
    }
    // Base._w.addEventListener(EventName.RESIZE, this.checkRatio);
    // this.checkRatio();
  }

  public arrShuffle([...array]) {
    for (let i = array.length - 1; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  public transitionLoop(): void {
    this._swiper.slideNext();
    setTimeout(() => {
      Array.prototype.forEach.call(this._sliderItemTxt, (txt) => {
        Base.removeClass(txt, "is-off");
      });
    }, 550);
  }
  // public checkRatio(): void {
  //   const ww: number = Base._w.innerWidth;
  //   const wh: number = Base._w.innerHeight;
  //   const image: HTMLElement | null = this._slider ? this._slider.querySelector(".swiper-slide img") : null;
  //   const iw: number = image ? image.clientWidth : 0;
  //   const ih: number = image ? image.clientHeight : 0;
  //   if (ww / wh > iw / ih) {
  //     Base.addClass(this._top, "is-beside");
  //     Base.removeClass(this._top, "is-vertical");
  //   } else {
  //     Base.removeClass(this._top, "is-beside");
  //     Base.addClass(this._top, "is-vertical");
  //   }
  // }
}
