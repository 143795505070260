import Base from "../utils/base";
import EventName from "../utils/eventName";

export default class FaqAside {
  private _seriesBtn: NodeListOf<HTMLElement> | null;
  constructor() {
    // class変数
    console.log("[init] FaqAside");
    this._seriesBtn = Base._d.querySelectorAll(".js-seriesBtn");
    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
    Array.prototype.forEach.call(this._seriesBtn, (btn) => {
      btn.addEventListener(EventName.CLICK, this.toggleClass.bind(this));
    });
  }
  public toggleClass(e: Event): void {
    const tgt: HTMLElement = e.target as HTMLElement;
    if (Base.hasClass(tgt, "is-open")) {
      Base.removeClass(tgt, "is-open");
    } else {
      Base.addClass(tgt, "is-open");
    }
    e.preventDefault();
  }
}
